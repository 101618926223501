import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Strong, Em, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Академія DriveMaster
			</title>
			<meta name={"description"} content={"В Академії DriveMaster ми прагнемо надати першокласну освіту водінню, щоб переконатися, що ви станете впевненим, відповідальним і безпечним водієм."} />
			<meta property={"og:title"} content={"Академія DriveMaster"} />
			<meta property={"og:description"} content={"В Академії DriveMaster ми прагнемо надати першокласну освіту водінню, щоб переконатися, що ви станете впевненим, відповідальним і безпечним водієм."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link5" />
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="link4" />
			<Override slot="link2" />
		</Components.Header>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-lightD1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 80px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				lg-width="100%"
				md-margin="0px 0px 50px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Наші комплексні пропозиції курсів
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL1" font="--lead" lg-text-align="center">
					В DriveMaster Academy ми пишаємося тим, що пропонуємо різноманітні курси водіння, розроблені відповідно до потреб кожного учня. Незалежно від того, чи ви новачок, який прагне навчитися основам, чи досвідчений водій, який прагне вдосконалити свої навички, у нас є правильний курс для вас. Наша навчальна програма розроблена, щоб озброїти вас знаннями, навичками та впевненістю, щоб безпечно та відповідально керувати всіма типами доріг.
				</Text>
			</Box>
			<Box
				width="50%"
				padding="0px 0px 0px 32px"
				lg-width="100%"
				display="flex"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					display="block"
					grid-template-rows="repeat(3, 180px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="32px"
					max-width="600px"
					md-grid-template-rows="repeat(3, 25vw)"
					sm-grid-gap="16px"
				>
					<Image
						src="https://uploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09:20:28.488Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						height="100%"
						grid-column="1 / span 1"
						grid-row="1 / span 2"
						srcSet="https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09%3A20%3A28.488Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09%3A20%3A28.488Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09%3A20%3A28.488Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09%3A20%3A28.488Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09%3A20%3A28.488Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09%3A20%3A28.488Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665448c7f0cb1d0025f5a1d0/images/1-4.jpg?v=2024-05-27T09%3A20%3A28.488Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Product-3">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 2rem 0px"
				font="normal 600 56px/1.2 --fontFamily-sans"
				text-align="center"
				lg-width="70%"
				md-width="100%"
			>
				Курси
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 50px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text margin="0px 0px 15px 0px" font="--lead" text-align="left">
						<Strong>
							Курси водіння для початківців: ідеальні для новачків, охоплюють основи, дорожні знаки та базові маневри.
						</Strong>
					</Text>
					<Text margin="0px 0px 15px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansHelvetica">
						Теоретичні заняття: Зрозумійте правила дорожнього руху за допомогою інтерактивних занять у класі.
    Практичні заняття: здобудьте впевненість за кермом, практикуючись під наглядом.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text margin="0px 0px 15px 0px" font="--lead" text-align="left">
						<Strong>
							Просунуті техніки водіння: для водіїв, які бажають вдосконалити свої навички, зосереджуючись на захисному водінні, нічному водінні та реагуванні на надзвичайні ситуації.
						</Strong>
					</Text>
					<Text margin="0px 0px 15px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansHelvetica">
						Захисне водіння: навчіться передбачати потенційні небезпеки та ефективно справлятися з ними.
    Водіння по шосе: опануйте навички, необхідні для водіння по високошвидкісних дорогах.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text margin="0px 0px 15px 0px" font="--lead" text-align="left">
						<Strong>
							Курси підвищення кваліфікації: призначені для людей, які хочуть вдосконалити свої навички водіння або відновити впевненість після періоду бездіяльності.
						</Strong>
					</Text>
					<Text margin="0px 0px 15px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansHelvetica">
						Індивідуальні уроки: вирішуйте конкретні проблеми або проблемні області за допомогою персоналізованих інструкцій.
    Гнучкий графік: курси доступні в різний час відповідно до вашого способу життя та зобов’язань.
					</Text>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 50px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text margin="0px 0px 15px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansHelvetica">
						<Strong>
							Наші інструктори не просто вправні водії – вони пристрасні викладачі, віддані навчанню безпечного водіння. Кожен інструктор має багатий досвід і терплячий, розуміючий підхід до навчання. Це гарантує, що кожен студент отримує особисту увагу та керівництво, адаптоване до його індивідуального темпу та стилю навчання.
						</Strong>
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text margin="0px 0px 15px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansHelvetica">
						<Em>
							Перераховані вище послуги – це лише початок того, що ми пропонуємо в DriveMaster Academy. Кожен курс розроблено з урахуванням вашої безпеки та зручності, забезпечуючи комплексний досвід навчання, який виходить за рамки основ. Ми запрошуємо вас відвідати нас або зв’язатися з нашою дружньою командою для більш детального вивчення повного спектру наших програм водіння.
						</Em>
					</Text>
				</Box>
			</Box>
			<Button
				padding="14px 34px 14px 34px"
				background="--color-secondary"
				font="normal 400 17px/1.5 --fontFamily-sans"
				href="/contacts"
				type="link"
				text-decoration-line="initial"
			>
				Зв'яжіться з нами для отримання додаткової інформації
			</Button>
		</Section>
		<Components.Footer>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="link4" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65c4dc440072400020e9c6f3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});